import { collection, query, where, onSnapshot, getDocs, doc, updateDoc, setDoc, orderBy, Timestamp } from 'firebase/firestore';
import { db } from './config_firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './config_firebase';


const getMySellers = async (idAdmin) => {
    console.log('idAdmin', idAdmin);
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'seller'),
        where('status', '==', 'active'),
        where('idAdmin', '==', idAdmin)
    ));

    const fetchedSellers = [];
    querySnapshot.forEach((doc) => {
        fetchedSellers.push({ id: doc.id, ...doc.data() });
    });

    return fetchedSellers;
}

const getAllSellers = async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'seller'),
        where('status', '==', 'active'),
        orderBy('name', 'asc')
    ));

    const fetchedSellers = [];
    querySnapshot.forEach((doc) => {
        fetchedSellers.push({ id: doc.id, ...doc.data() });
    });

    return fetchedSellers;
}

export const getAllPromotors= async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'sellerProbably'),
        where('status', '==', 'active'),
        orderBy('name', 'asc')
    ));

    const fetchedSellers = [];
    querySnapshot.forEach((doc) => {
        fetchedSellers.push({ id: doc.id, ...doc.data() });
    });

    return fetchedSellers;
}

export const createPromoter = async (data) => {
    try {
        const createdUser = await createUserWithEmailAndPassword(
            auth,
            data.email,
            '123456'
        );

        await setDoc(doc(db, 'users', createdUser.user.uid), {
            id: createdUser.user.uid,
            name: data.name,
            email: data.email,
            phone: data.phone,
            image:
                'https://maxipos.cl/wp-content/uploads/2022/11/Frame-32@2x.png',
            type: 'sellerProbably',
            status: 'active',
            createdAt: Timestamp.now(),
            idAdmin: data.idAdmin,
        });

        return true;
    } catch (error) {
        console.error('Error creating seller:', error);
        return false;
    }
};


const createSeller = async (data) => {
    try {
        const createdUser = await createUserWithEmailAndPassword(
            auth,
            data.email,
            '123456'
        );

        await setDoc(doc(db, 'users', createdUser.user.uid), {
            id: createdUser.user.uid,
            name: data.name,
            email: data.email,
            phone: data.phone,
            rut: data.rut,
            image:
                'https://firebasestorage.googleapis.com/v0/b/maxipos-dbd94.appspot.com/o/imagesRegisters%2Fiso_maxi.png?alt=media&token=091df1df-5295-47ad-88d2-616a206c2606',
            type: 'seller',
            status: 'active',
            idAdmin: data.idAdmin,
        });

        return true;
    } catch (error) {
        console.error('Error creating seller:', error);
        return false;
    }
};

const updateSeller = async (id, data) => {
    try {
        await updateDoc(doc(db, 'users', id), {
            name: data.name,
            phone: data.phone,
        });
        return true;
    } catch (error) {
        console.error('Error updating seller:', error);
        return false;
    }
};

export { getMySellers, createSeller, updateSeller, getAllSellers };