import React, { useEffect, useState } from 'react'
import ReusableDrawer from '../components/drawer';
import { Box, ListItem, ListItemAvatar, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import loadingImg from '../assets/loading.svg';
import colors from '../utils/colors';
import { getPossibleClients } from '../database/possible_clients';
import { capitalizeWords } from '../utils/capitalize';
import maleImage from '../assets/male.png'
import ModalPossibleClient from '../components/modal_possible_client';

export default function PossibleClients() {

    const [drawerOpen, setDrawerOpen] = useState(true);
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [activeClients, setActiveClients] = useState([]);
    const [pendingClients, setPendingClients] = useState([]);
    const [declinedClients, setDeclinedClients] = useState([]);

    const [clients, setClients] = useState([]);
    const [sellers, setSellers] = useState([]);

    const [selectedRequest, setSelectedRequest] = useState({});

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const theme = useTheme();
    const isSmallScreen = false // useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery('(max-width:1366px)');

    async function getData() {
        const response = await getPossibleClients();
        console.log("Response, ", response);

        setActiveClients(response.accepted)
        setPendingClients(response.pending)
        setDeclinedClients(response.declined)
        setClients(response.pending)
        console.log(clients);

        setLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])



    return (
        <div style={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#F7F7F8'
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'possibleClients'} />

            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: isSmallScreen ? '80px' : '100px', height: isSmallScreen ? '80px' : '100px' }} />
                    <p style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>Cargando solicitudes...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    backgroundColor: '#F7F7F8'
                }}>
                    <h2 style={{
                        color: colors.green,
                        fontSize: isSmallScreen ? '1.2rem' : '1.5rem'
                    }}>Posibles clientes</h2>

                    {
                        clients.map((client) => (
                            <ListItem
                                onClick={() => {
                                    setSelectedRequest(client);
                                    setOpenModal(true);
                                }}
                                key={client.id}
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: isSmallScreen ? '95%' : isMediumScreen ? '85%' : '75%',
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    border: '1px solid #1212121F',
                                    margin: isSmallScreen ? '3px' : '5px',
                                    padding: isSmallScreen ? '8px' : '10px'
                                }}
                            >
                                <ListItemAvatar>
                                    <img src={client.imageUrl == 'empty' ? maleImage : client.imageUrl} alt="Logo" style={{ width: isSmallScreen ? '40px' : '50px', height: isSmallScreen ? '40px' : '50px', borderRadius: '50%' }} />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ ml: 1 }}
                                    primaryTypographyProps={{
                                        fontFamily: 'Nunito',
                                        fontSize: isSmallScreen ? '0.85rem' : '1rem'
                                    }}
                                    secondaryTypographyProps={{
                                        fontFamily: 'Nunito',
                                        fontSize: isSmallScreen ? '0.75rem' : '0.9rem'
                                    }}
                                    primary={capitalizeWords(client.fantasyName)}
                                    secondary={`Dirección: ${capitalizeWords(client.address)}`}
                                // secondary={`Vendedor a cargo: ${clients.find(seller => seller.id === request.idSeller).name}, ${request.addressEnterprise}, ${request.comuneEnterprise}`}
                                />
                                <ListItemIcon>
                                    <ArrowForwardIos sx={{ fontSize: isSmallScreen ? '1rem' : '1.2rem' }} />
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }
                </Box>
            )}

            {
                openModal && (
                    <ModalPossibleClient
                        data={selectedRequest}
                        handleClose={() => {
                            setOpenModal(false)
                        }}
                        reloadData={getData}
                        open={openModal} />
                )
            }

            {/* {openModal && (
            <ModalAcceptClient
              reloadData={getRequests}
              isOpen={openModal}
              data={selectedRequest}
              nameSeller={sellers.find(seller => seller.id === selectedRequest.idSeller).name}
              onClose={() => setOpenModal(false)}
            />
          )} */}
        </div>
    );
}
