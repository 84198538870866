import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogActions, DialogContent,
  Button, TextField, Typography, Box, List, ListItem, ListItemText, Radio, RadioGroup,
  ListItemAvatar,
  Avatar,
  ListItemIcon
} from '@mui/material';
import { AirplaneTicket, BackHand, Build, DeleteForever, Money, Print, WifiOff } from '@mui/icons-material';
import { getAllSellers } from '../database/sellers';
import { capitalizeWords } from '../utils/capitalize';
import colors from '../utils/colors';
import Swal from 'sweetalert2';
import { getAllClients, getAllClientsByName } from '../database/clients';
import loadingSVG from '../assets/loading.svg'
import Select from 'react-select';
import { createRequest } from '../database/support_queries';

const ModalNewRequest = ({ open, handleClose }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [details, setDetails] = useState("");
  const [openAssignVendor, setOpenAssignVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [dataSelectedVendor, setDataSelectedVendor] = useState({})
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null);

  const items = [
    { name: 'Rollos de papel', icon: <Print /> },
    { name: 'Problemas con boletas electrónicas', icon: <AirplaneTicket /> },
    { name: 'Problema con facturas electrónicas', icon: <Money /> },
    { name: 'Problema con los abonos', icon: <BackHand /> },

    // { name: 'Asistencia técnica', icon: <Build /> },
    // { name: 'Problemas de internet', icon: <WifiOff /> },
  ];

  const [vendors, setVendors] = useState([])

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDetails("");
  };

  const handleAssignToVendor = () => {
    setOpenAssignVendor(true); // Abrir el modal de vendedores
  };

  const handleCloseVendorModal = (cancel) => {
    if (cancel) {
      setSelectedVendor(null)
    }
    setOpenAssignVendor(false);
  };

  const handleVendorSelection = (event, isRadio) => {

    console.log(event);

    if (isRadio) {
      event.stopPropagation()

      setSelectedVendor(event.target.value);
    } else {
      setSelectedVendor(event.id)
      setDataSelectedVendor(event)
    }
  };

  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando datos...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const response = await getAllClientsByName();
      setClients(response.map(client => ({
        value: client.id,
        label: capitalizeWords(client.socialReason)
      })));
      console.log("Clientes cargados");

      const sellers = await getAllSellers();
      setVendors(sellers)
      console.log("Finalizada la func get Data en el use effect");
      Swal.close();
    }

    getData();

  }, [])

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    console.log('Cliente seleccionado:', selectedOption);
  };

  const finishAssignToVendor = async () => {

    let dataCreate = {
      extraData: details,
      nameClient: "",
      addressClient: "",
      latitude: "",
      longitude: "",
      priority: "",
      type: "",
    }
    const response = await createRequest(dataCreate, selectedVendor, selectedClient, false);
  }

  const finishAssignToSupport = () => { }



  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{
        zIndex: 0
      }}>
        <DialogTitle>Nueva solicitud de soporte</DialogTitle>
        <DialogContent>
          <Typography>Selecciona al cliente que necesita soporte</Typography>
          <Select
            value={selectedClient}
            onChange={handleClientChange}
            options={clients}
            placeholder="Selecciona un cliente"
          />
          <br />
          <Typography>Selecciona el tipo de solicitud</Typography>
          <Box sx={{ display: 'flex' }}>
            {items.map((item) => (
              <Box
                key={item.name}
                onClick={() => handleItemClick(item)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  width: '100px',
                  height: '100px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: selectedItem?.name === item.name ? `2px solid ${colors.green}` : '1px solid #ccc',
                  padding: '16px',
                  transition: 'border 0.3s',
                  "&:hover": {
                    borderColor: '#3f51b5'
                  },
                  m: 1
                }}
              >
                {item.icon}
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
          {selectedItem && (
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: '17px' }}>Detalles para solicitud: {selectedItem.name}</Typography>
              <TextField
                placeholder="Detalles adicionales"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                sx={{ mt: 2 }}
              />
            </Box>
          )}
          {
            selectedVendor && (
              <>
                <br />
                <Typography>Solicitud asignada a:</Typography>
                <ListItem
                  sx={{
                    margin: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '99%',
                    backgroundColor: dataSelectedVendor.id === selectedVendor ? colors.greenBackground : '#f5f5f5',
                    borderRadius: '10px',
                    border: `1px solid ${dataSelectedVendor.id === selectedVendor ? colors.green : '#1212121F'}`,
                  }} key={dataSelectedVendor.id}>
                  <ListItemAvatar>
                    <Avatar sx={{
                      // backgroundColor: '#1212121F',
                      border: '1px solid #1212121F'
                    }} src={dataSelectedVendor.image} />
                  </ListItemAvatar>
                  <ListItemText primary={capitalizeWords(dataSelectedVendor.name)} />
                  <ListItemIcon>
                    <DeleteForever
                      onClick={(e) => {
                        e.stopPropagation();
                        Swal.fire({
                          customClass: 'swal-overlay',
                          title: '¿Deseas eliminar al vendedor a cargo?',
                          text: "Puedes volver a seleccionarlo después de eliminarlo.",
                          showDenyButton: true,
                          confirmButtonText: `Aceptar`,
                          denyButtonText: `Cancelar`,
                          confirmButtonColor: colors.green,
                          denyButtonColor: colors.red
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setSelectedVendor(null)
                            setDataSelectedVendor({})
                          }
                        })
                      }}
                      sx={{
                        color: 'black',
                        cursor: 'pointer'
                      }} />
                  </ListItemIcon>
                </ListItem>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancelar</Button>
          {
            !selectedVendor && (
              <Button onClick={finishAssignToSupport} color="primary">
                Asignar a soporte
              </Button>
            )
          }

          <Button onClick={() => {
            if (selectedVendor) {
              finishAssignToVendor();
            } else {
              handleAssignToVendor();
            }
          }} color="primary">
            {
              selectedVendor ? 'Terminar solicitud' : 'Asignar a vendedor'
            }
          </Button>
        </DialogActions>
      </Dialog>


      {
        openAssignVendor && (
          <Dialog open={openAssignVendor} onClose={handleCloseVendorModal}>
            <DialogTitle>Selecciona un vendedor</DialogTitle>
            <DialogContent>

              {vendors.map((vendor) => (
                <ListItem
                  button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleVendorSelection(vendor, false)
                  }}
                  sx={{
                    cursor: 'pointer',
                    margin: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '99%',
                    backgroundColor: vendor.id === selectedVendor ? colors.greenBackground : '#f5f5f5',
                    borderRadius: '10px',
                    border: `1px solid ${vendor.id === selectedVendor ? colors.green : '#1212121F'}`,
                  }} key={vendor.id}>
                  <ListItemAvatar>
                    <Avatar sx={{
                      // backgroundColor: '#1212121F',
                      border: '1px solid #1212121F'
                    }} src={vendor.image} />
                  </ListItemAvatar>
                  <ListItemText primary={capitalizeWords(vendor.name)} />
                </ListItem>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                handleCloseVendorModal(true)
              }} color="primary">Cancelar</Button>
              <Button onClick={() => {
                handleCloseVendorModal(false)
              }} color="primary">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>)
      }
    </>
  );
};

export default ModalNewRequest;
