import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Container, Divider, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, useMediaQuery } from '@mui/material';
import { ExitToApp, HomeOutlined, MailOutline, Person3Outlined, PersonOutline, QuestionMark, SettingsOutlined, SupportAgent } from '@mui/icons-material';
import logo from '../assets/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../utils/colors';
import maxipos from '../assets/spark.svg';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../database/config_firebase';
import { logout } from "../actions/auth_actions";
import { capitalize } from '../utils/capitalize';
import { useTheme } from '@mui/material/styles';

const ReusableDrawer = ({ onClose, anchor = 'left', route }) => {

  const userData = useSelector(state => state.auth.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery('(max-width:1366px)');  // Detectar resolución específica como 1366px

  const handleLogout = () => {
    dispatch(logout());
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <Drawer
      anchor={anchor}
      open={true}
      onClose={onClose}
      variant="permanent"
      sx={{
        zIndex: 0,
        width: isSmallScreen ? '55%' : isMediumScreen ? '200px' : '280px',  // Reducir el ancho en pantallas medianas
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isSmallScreen ? '55%' : isMediumScreen ? '200px' : '280px',
          boxSizing: 'border-box',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        },
      }}
    >
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        mt: 2
      }}>
        <img src={logo} width={'80%'} alt="Logo" /> {/* Ajustar el tamaño del logo */}
      </Container>
      <Divider variant="middle" />
      <ListItem sx={{
        mt: 2,
        ml: 2,
        border: '1px solid #1212121F',
        backgroundColor: '#F7F7F8',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        width: '90%',
        borderRadius: '10px',
        padding: '8px',  // Reducir el padding para evitar tamaños grandes
      }}>
        <ListItemAvatar>
          <Avatar sx={{
            border: '1px solid #1212121F',
          }} src={maxipos} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold' }}  // Reducir el tamaño del texto
          secondaryTypographyProps={{ fontSize: '0.75rem' }}  // Reducir el tamaño del texto secundario
          primary={userData && userData.name ? userData.name : 'Admin'}
          secondary={userData && userData.type ? capitalize(userData.type) : ''}
        />
      </ListItem>
      <List sx={{ mt: 2, flex: 1, justifyContent: 'flex-start', overflowY: 'auto' }}>  {/* Habilitar scroll si es necesario */}
        <Container>
          <ListItem
            onClick={() => { navigate('/home'); }}
            sx={{
              borderRadius: '10px',
              fontWeight: 'bold',
              color: route === 'home' ? colors.green : 'black',
              backgroundColor: route === 'home' ? colors.greenBackground : 'transparent',
              border: route === 'home' ? `1px solid ${colors.green}` : 'none',
              padding: '8px',  // Reducir el padding de los ListItems
              fontSize: '0.85rem',  // Reducir el tamaño del texto
              mt: 1
            }} button>
            <ListItemIcon>
              <HomeOutlined sx={{ fontSize: '1.1rem', color: route === 'home' ? colors.green : 'black' }} /> {/* Reducir el tamaño del icono */}
            </ListItemIcon>
            Inicio
          </ListItem>
          <ListItem
            onClick={() => { navigate('/sellers'); }}
            sx={{
              borderRadius: '10px',
              padding: '8px',
              fontSize: '0.85rem',
              mt: 1,
              fontWeight: 'bold',
              color: route === 'sellers' ? colors.green : 'black',
              backgroundColor: route === 'sellers' ? colors.greenBackground : 'transparent',
              border: route === 'sellers' ? `1px solid ${colors.green}` : 'none',
            }} button>
            <ListItemIcon>
              <Person3Outlined sx={{ fontSize: '1.1rem', color: route === 'sellers' ? colors.green : 'black' }} /> {/* Reducir el tamaño del icono */}
            </ListItemIcon>
            Vendedores
          </ListItem>
          <ListItem
            onClick={() => { navigate('/promoters'); }}
            sx={{
              borderRadius: '10px',
              padding: '8px',
              fontSize: '0.85rem',
              mt: 1,
              fontWeight: 'bold',
              color: route === 'promoters' ? colors.green : 'black',
              backgroundColor: route === 'promoters' ? colors.greenBackground : 'transparent',
              border: route === 'promoters' ? `1px solid ${colors.green}` : 'none',
            }} button>
            <ListItemIcon>
              <Person3Outlined sx={{ fontSize: '1.1rem', color: route === 'promoters' ? colors.green : 'black' }} /> {/* Reducir el tamaño del icono */}
            </ListItemIcon>
            Promotores
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/clients');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              padding: '8px',
              fontSize: '0.85rem',
              fontWeight: 'bold',
              color: route === 'clients' ? colors.green : 'black',
              backgroundColor: route === 'clients' ? colors.greenBackground : 'transparent',
              border: route === 'clients' ? `1px solid ${route === 'clients' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <PersonOutline sx={{
                fontSize: '1.1rem',
                color: route === 'clients' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Clientes
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/possibleClients');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              padding: '8px',
              fontSize: '0.85rem',
              fontWeight: 'bold',
              color: route === 'possibleClients' ? colors.green : 'black',
              backgroundColor: route === 'possibleClients' ? colors.greenBackground : 'transparent',
              border: route === 'possibleClients' ? `1px solid ${route === 'possibleClients' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <QuestionMark sx={{
                fontSize: '1.1rem',
                color: route === 'possibleClients' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Posibles clientes
          </ListItem>
          {/* <ListItem
            onClick={() => {
              navigate('/debtorClients');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              fontWeight: 'bold',
              color: route === 'debtors' ? colors.green : 'black',
              backgroundColor: route === 'debtors' ? colors.greenBackground : 'transparent',
              border: route === 'debtors' ? `1px solid ${route === 'debtors' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <Person2Outlined sx={{
                color: route === 'debtors' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Clientes morosos
          </ListItem> */}
          <ListItem
            onClick={() => {
              navigate('/emails');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1, padding: '8px',
              fontSize: '0.85rem',
              fontWeight: 'bold',
              color: route === 'emails' ? colors.green : 'black',
              backgroundColor: route === 'emails' ? colors.greenBackground : 'transparent',
              border: route === 'emails' ? `1px solid ${route === 'emails' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <MailOutline sx={{
                fontSize: '1.1rem',
                color: route === 'emails' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Correos
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/settings');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1, padding: '8px',
              fontSize: '0.85rem',
              fontWeight: 'bold',
              color: route === 'settings' ? colors.green : 'black',
              backgroundColor: route === 'settings' ? colors.greenBackground : 'transparent',
              border: route === 'settings' ? `1px solid ${route === 'settings' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <SettingsOutlined sx={{
                fontSize: '1.1rem',
                color: route === 'settings' ? colors.green : 'black',
              }} />
            </ListItemIcon>
            Ajustes
          </ListItem>
        </Container>
        <Container style={{ position: 'absolute', bottom: 10, width: '100%' }}>
          <ListItem button
            onClick={() => { navigate('/support'); }}
            sx={{
              border: route === 'support' ? `1px solid ${colors.green}` : 'none',
              borderRadius: '10px',
              padding: '8px',
              fontSize: '0.85rem',
              fontWeight: 'bold',
              backgroundColor: route === 'support' ? colors.greenBackground : 'transparent',
              color: route === 'support' ? colors.green : 'black'
            }}>
            <ListItemIcon>
              <SupportAgent sx={{ fontSize: '1.1rem', color: route === 'support' ? colors.green : 'black' }} /> {/* Reducir el tamaño del icono */}
            </ListItemIcon>
            Soporte técnico
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout} sx={{
            borderRadius: '10px',
            padding: '8px',  // Reducir el padding
            fontSize: '0.85rem',  // Reducir el tamaño del texto
            fontWeight: 'bold',
            color: 'black',
          }}>
            <ListItemIcon>
              <ExitToApp sx={{ fontSize: '1.1rem' }} /> {/* Reducir el tamaño del icono */}
            </ListItemIcon>
            Cerrar sesión
          </ListItem>
        </Container>
      </List>
    </Drawer>
  );
};

ReusableDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
};

export default ReusableDrawer;
