import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Grid, Avatar, Button, Divider } from '@mui/material';
import { getUserById } from '../database/clients';
import defaultImage from '../assets/male.png';
import colors from '../utils/colors';
import { capitalizeWords } from '../utils/capitalize';
import { updatePossibleClient } from '../database/possible_clients';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
};

const ModalPossibleClient = ({ open, handleClose, data, reloadData }) => {
    const [sellerName, setSellerName] = useState("Cargando...");
    const [formattedDate, setFormattedDate] = useState("");

    const sendStatus = async (id, status) => {
        try {
            const response = await updatePossibleClient(id, status);
            if (response) {
                Swal.fire({
                    customClass: 'swal-overlay',
                    title: 'Realizado',
                    icon: 'success',
                    text: status == 'declined' ? 'El posible cliente se ha rechazado.' : status == 'accepted' ? 'El posible cliente se ha aceptado' : 'Se ha cambiado el estado del posible cliente.'
                });
            } else {
                Swal.fire({
                    customClass: 'swal-overlay',
                    title: 'Error',
                    icon: 'error',
                    text: 'No se han podido actualizar los datos. Inténtalo nuevamente.'
                });
            }
        } catch (error) {
            Swal.fire({
                customClass: 'swal-overlay',
                title: 'Error',
                icon: 'error',
                text: 'No se han podido actualizar los datos. Inténtalo nuevamente.'
            });
        }
    }

    useEffect(() => {
        async function getData() {
            const response = await getUserById(data.idSeller);
            console.log(data);
            
            console.log(response);
            
            setSellerName(response.name);
        }

        if (data.createdAt) {
            console.log(data);

            const date = data.createdAt.toDate();
            const formatted = new Intl.DateTimeFormat('es-ES', {
                weekday: 'short',
                day: '2-digit',
                month: 'long',
                year: 'numeric',
            }).format(date);
            setFormattedDate(formatted);
        }

        getData();
    }, [data.createdAt, data.idSeller]);

    return (
        <Modal sx={{
            zIndex: 1
        }} open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                    Información del cliente
                </Typography>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Avatar
                            src={data.imageUrl === 'empty' ? defaultImage : data.imageUrl}
                            alt={data.fantasyName}
                            sx={{ width: 100, height: 100 }}
                        />
                        <Typography
                            sx={{
                                color: colors.green,
                                mt: 1
                            }}
                            variant="h6"
                        >
                            <strong>{capitalizeWords(data.fantasyName)}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Promotor a cargo:</strong> {sellerName}
                        </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Dirección:</strong> {capitalizeWords(data.address)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Contacto:</strong> {capitalizeWords(data.nameContact)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Email:</strong> {capitalizeWords(data.email)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Fecha Creación:</strong> {capitalizeWords(formattedDate)}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                    <Button
                        variant="text"
                        sx={{
                            mr: 1,
                            color: colors.red,
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>

                    <Button
                        variant="text"
                        sx={{
                            mr: 1,
                            color: colors.orange,
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            console.log('Rechazado');
                            sendStatus(data.id, 'declined');
                            handleClose();
                            reloadData();
                        }}
                    >
                        Rechazar
                    </Button>

                    <Button
                        variant="text"
                        sx={{
                            color: colors.green,
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            console.log('Confirmado');
                            sendStatus(data.id, 'accepted');
                            handleClose();
                            reloadData();
                        }}
                    >
                        Aceptar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalPossibleClient;
