import React from 'react'
import logo from '../assets/logo.svg';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Lock, Mail, Visibility, VisibilityOff } from '@mui/icons-material';
import { auth } from "../database/config_firebase";
import { signInWithEmailAndPassword } from 'firebase/auth';
import Swal from 'sweetalert2';
import loadingSVG from '../assets/loading.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from "../actions/auth_actions";
import { getUserById } from '../database/clients';
import { useMediaQuery } from '@mui/material';

export default function Login() {
  const dispatch = useDispatch();
  const handleLogin = (userData, userId) => {
    dispatch(login(userData, userId));
  };
  
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const onLogin = async (e) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email.trim(), password);
      const user = userCredential.user;
      const resUser = await getUserById(user.uid);
      handleLogin(resUser, user.uid);
      navigate("/home");
      Swal.close();
    } catch (error) {
      Swal.close();
      if (error.code === "auth/wrong-password") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Contraseña incorrecta. Verifica tus credenciales.",
        });
      } else if (error.code === "auth/too-many-requests") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Demasiados intentos fallidos. Inténtalo después.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Correo no encontrado. Verifica tus datos.",
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      padding: isSmallScreen ? '10px' : '20px',
    }}>
      <div style={{
        width: isSmallScreen ? '100%' : '400px', // Cambia según el tamaño de pantalla
        maxWidth: '100%',
        padding: isSmallScreen ? '15px' : '20px',
        height: isSmallScreen ? 'auto' : '400px',
        backgroundColor: '#f5f5f5',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img src={logo} alt="Logo" style={{ width: '60%', height: 'auto', marginBottom: '20px' }} />
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail sx={{ color: 'black' }} />
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: '#1212121F',
            m: 1,
            borderRadius: '10px',
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
          }}
        />
        <TextField
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock sx={{ color: 'black' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: '#1212121F',
            m: 1,
            borderRadius: '10px',
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
          }}
        />
        <Button
          onClick={onLogin}
          variant="contained"
          sx={{
            backgroundColor: '#0C9A41',
            color: 'white',
            m: 1,
            width: '100%',
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: '#0C9A41',
            }
          }}
        >
          Iniciar Sesión
        </Button>
      </div>
    </div>
  );
}
