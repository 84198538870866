import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import { Box, Container, ListItem, ListItemAvatar, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import colors from '../utils/colors';
import { getPendingRequests } from '../database/requests';
import loadingImg from '../assets/loading.svg';
import { ArrowForwardIos } from '@mui/icons-material';
import ModalAcceptClient from '../components/modal_accept_client';
import { getAllSellers } from '../database/sellers';
import { useTheme } from '@mui/material/styles';

export default function Home() {

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState({});
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));  
  const isMediumScreen = useMediaQuery('(max-width:1366px)');

  async function getRequests() {
    setLoading(true);
    const response = await getPendingRequests();
    const sellersResponse = await getAllSellers();
    setSellers(sellersResponse);
    setRequests(response);
    setLoading(false);
  }

  useEffect(() => {
    getRequests();
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: isSmallScreen ? 'column' : 'row',  // En pantallas pequeñas se coloca en columna
      justifyContent: 'center',
      alignItems: 'start',
      height: '100vh',
      width: '100vw',
      backgroundColor: '#F7F7F8'
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'home'} />

      {loading ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <img src={loadingImg} alt="loading" style={{ width: isSmallScreen ? '80px' : '100px', height: isSmallScreen ? '80px' : '100px' }} /> {/* Imagen más pequeña en pantallas pequeñas */}
          <p style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>Cargando solicitudes...</p> {/* Texto más pequeño */}
        </Box>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}>
          <h2 style={{
            color: colors.green,
            fontSize: isSmallScreen ? '1.2rem' : '1.5rem'  // Ajustar tamaño del título
          }}>Solicitudes pendientes</h2>
          
          {
            requests.map((request) => (
              <ListItem
                onClick={() => {
                  setSelectedRequest(request);
                  setOpenModal(true);
                }}
                key={request.id}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: isSmallScreen ? '95%' : isMediumScreen ? '85%' : '75%',  // Ancho ajustado según la pantalla
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  border: '1px solid #1212121F',
                  margin: isSmallScreen ? '3px' : '5px',  // Márgenes más pequeños en pantallas pequeñas
                  padding: isSmallScreen ? '8px' : '10px'  // Padding ajustado
                }}
              >
                <ListItemAvatar>
                  <img src={request.restaurant} alt="Logo" style={{ width: isSmallScreen ? '40px' : '50px', height: isSmallScreen ? '40px' : '50px', borderRadius: '50%' }} /> {/* Imagen más pequeña en pantallas pequeñas */}
                </ListItemAvatar>
                <ListItemText
                  sx={{ ml: 1 }}
                  primaryTypographyProps={{
                    fontFamily: 'Nunito',
                    fontSize: isSmallScreen ? '0.85rem' : '1rem'  // Texto ajustado para pantallas pequeñas
                  }}
                  secondaryTypographyProps={{
                    fontFamily: 'Nunito',
                    fontSize: isSmallScreen ? '0.75rem' : '0.9rem'  // Texto secundario ajustado
                  }}
                  primary={request.fantasyName != null ? request.fantasyName : request.socialReason}
                  secondary={`Vendedor a cargo: ${sellers.find(seller => seller.id === request.idSeller).name}, ${request.addressEnterprise}, ${request.comuneEnterprise}`} 
                />
                <ListItemIcon>
                  <ArrowForwardIos sx={{ fontSize: isSmallScreen ? '1rem' : '1.2rem' }} />  {/* Icono más pequeño en pantallas pequeñas */}
                </ListItemIcon>
              </ListItem>
            ))
          }
        </Box>
      )}

      {openModal && (
        <ModalAcceptClient
          reloadData={getRequests}
          isOpen={openModal}
          data={selectedRequest}
          nameSeller={sellers.find(seller => seller.id === selectedRequest.idSeller).name}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
