import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Grid, Avatar, Button, Divider } from '@mui/material';
import { getUserById } from '../database/clients';
import defaultImage from '../assets/male.png';
import colors from '../utils/colors';
import { capitalizeWords } from '../utils/capitalize';
import { updatePossibleClient } from '../database/possible_clients';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
};

const ModalViewPromoter = ({ open, handleClose, data, reloadData }) => {
    const [sellerName, setSellerName] = useState("Cargando...");
    const [formattedDate, setFormattedDate] = useState("");

    useEffect(() => {

        if (data.createdAt) {
            console.log(data);

            const date = data.createdAt.toDate();
            const formatted = new Intl.DateTimeFormat('es-ES', {
                weekday: 'short',
                day: '2-digit',
                month: 'long',
                year: 'numeric',
            }).format(date);
            setFormattedDate(formatted);
        }

    }, [data.createdAt, data.idSeller]);

    return (
        <Modal sx={{
            zIndex: 1
        }} open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                    Datos del promotor
                </Typography>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Avatar
                            src={data.image === 'empty' ? defaultImage : data.image}
                            alt={data.name}
                            sx={{ width: 100, height: 100 }}
                        />
                        <Typography
                            sx={{
                                color: colors.green,
                                mt: 1
                            }}
                            variant="h6"
                        >
                            <strong>{capitalizeWords(data.name)}</strong>
                        </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />

                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Email:</strong> {capitalizeWords(data.email)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <strong>Fecha Creación:</strong> {capitalizeWords(formattedDate)}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                    <Button
                        variant="text"
                        sx={{
                            mr: 1,
                            color: colors.red,
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalViewPromoter;
