import { Close, Mail, Numbers, Person, Phone } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTextField from './textfield';
import RutTextField from './textfield_rut';
import colors from '../utils/colors';
import { updateSeller } from '../database/sellers';
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';

export default function ModalViewSeller({ data, isOpen, onClose }) {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [rut, setRut] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Pantallas pequeñas

    useEffect(() => {
        setId(data.id);
        setName(data.name);
        setRut(data.rut);
        setEmail(data.email);
        setPhone(data.phone);
    }, [data]);

    const editSeller = async () => {
        try {
            const response = await updateSeller(id, {
                name: name,
                phone: phone
            });

            if (response) {
                onClose();
                Swal.fire({
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    title: "Vendedor editado",
                    text: "El vendedor ha sido editado exitosamente.",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    confirmButtonText: "Aceptar",
                    title: "Oops...",
                    text: "Ocurrió un error al editar el vendedor. Inténtalo nuevamente.",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                confirmButtonText: "Aceptar",
                title: "Oops...",
                text: "Ocurrió un error al editar el vendedor. Inténtalo nuevamente.",
            });
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={onClose}
            open={isOpen}>
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ 
                        fontFamily: 'Nunito', 
                        fontWeight: 'bold', 
                        color: colors.green,
                        fontSize: isSmallScreen ? '1.2rem' : '1.5rem'  // Ajuste del tamaño del título en pantallas pequeñas
                    }}
                    gutterBottom
                >
                    Datos de vendedor
                </Typography>
                <Close onClick={onClose} style={{ cursor: "pointer", fontSize: isSmallScreen ? '1.5rem' : '2rem' }} /> {/* Tamaño del ícono */}
            </DialogTitle>
            <DialogContent>
                <CustomTextField 
                    label="Nombre"
                    controller={{
                        value: name,
                        setValue: setName
                    }}
                    unlocked={true}
                    fullWidth
                    icon={<Person sx={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }} />}  // Tamaño del icono ajustado
                />
                <RutTextField 
                    label="Rut"
                    controller={{
                        value: rut,
                        setValue: setRut
                    }}
                    unlocked={false}
                    fullWidth
                    icon={<Numbers sx={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }} />}  // Tamaño del icono ajustado
                />
                <CustomTextField 
                    label="Email"
                    controller={{
                        value: email,
                        setValue: setEmail
                    }}
                    unlocked={false}
                    fullWidth
                    icon={<Mail sx={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }} />}  // Tamaño del icono ajustado
                />
                <CustomTextField 
                    label="Teléfono"
                    controller={{
                        value: phone,
                        setValue: setPhone
                    }}
                    unlocked={true}
                    fullWidth
                    icon={<Phone sx={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }} />}  // Tamaño del icono ajustado
                />
            </DialogContent>
            <DialogActions>
                <Button sx={{
                    fontFamily: 'Nunito',
                    color: 'black',
                    textTransform: 'none',
                    fontSize: isSmallScreen ? '0.85rem' : '1rem'  // Tamaño del texto en pantallas pequeñas
                }} onClick={onClose}>Cancelar</Button>
                <Button
                    onClick={editSeller}
                    sx={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: colors.green,
                        textTransform: 'none',
                        fontSize: isSmallScreen ? '0.85rem' : '1rem'  // Tamaño del texto en pantallas pequeñas
                    }}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
