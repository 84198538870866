import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';

export const getPossibleClients = async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'possible_clients'),
        orderBy('createdAt', 'desc')
    ));
    const pendingClients = [];
    const activeClients = [];
    const declinedClients = [];
    console.log(querySnapshot.docs);
    
    querySnapshot.docs.forEach(doc => {
        if (doc.get('status') == 'pending') {
            pendingClients.push({ id: doc.id, ...doc.data() });
        } else if (doc.get('status') == 'accepted') {
            activeClients.push({ id: doc.id, ...doc.data() });
        } else if (doc.get('status') == 'declined') {
            declinedClients.push({ id: doc.id, ...doc.data() });
        }
    });
    return {
        pending: pendingClients,
        accepted: activeClients,
        declined: declinedClients
    };
}

export const updatePossibleClient = async (docId, newStatus) => {
    try {
        const docRef = doc(db, 'possible_clients', docId);
        await updateDoc(docRef, { status: newStatus });
        console.log(`Estado actualizado a: ${newStatus}`);
        return true;
    } catch (error) {
        console.error('Error al actualizar el estado:', error);
        return false;
    }
};