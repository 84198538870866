import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import { Box, Container, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography, Pagination, Fab, TextField, Popover, Menu, IconButton, MenuItem, InputAdornment, Avatar } from '@mui/material';
import colors from '../utils/colors';
import { getAllClients } from '../database/clients';
import { capitalize } from '../utils/capitalize';
import loadingImg from '../assets/loading.svg';
import { ArrowForwardIos, Add as AddIcon, MoreVert, Search, OpenInBrowserTwoTone } from '@mui/icons-material';
import ModalViewClient from '../components/modal_view_client';
import { getAllSellers } from '../database/sellers';
import Swal from 'sweetalert2';
import createExcel from '../utils/generate_excel';

export default function Clients() {

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [clients, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [selectedClient, setSelectedClient] = useState({});
  const [openViewClient, setOpenViewClient] = useState(false);
  const [sellers, setSellers] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);

  async function getRequests() {
    setLoading(true);
    const response = await getAllClients();
    const sellersResponse = await getAllSellers();
    setSellers(sellersResponse);
    setRequests(response);
    setLoading(false);
  }

  useEffect(() => {

    getRequests();
  }, []);

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = clients.filter(client =>
      (client.fantasyName && client.fantasyName.toLowerCase().includes(lowercasedFilter)) ||
      (client.socialReason && client.socialReason.toLowerCase().includes(lowercasedFilter)) ||
      (client.rutEnterprise && client.rutEnterprise.toLowerCase().includes(lowercasedFilter))
    );
    setFilteredClients(filteredData);
    setPage(1);
  }, [searchTerm, clients]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRequests = filteredClients.slice(startIndex, endIndex);

  const getSellerNameById = (id) => {
    const seller = sellers.find(seller => seller.id === id);
    return seller ? seller.name : '...';
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    'Descargar clientes'
  ];

  const openNewTab = async (url) => {
    window.open(url, '_blank');
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
      height: '100vh',
      width: '100%',
      backgroundColor: '#F7F7F8'
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'clients'} />
      {loading ? <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}>
        <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
        <p>Cargando clientes...</p>
      </Box> :
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '90%',
          alignItems: 'center',
          position: 'relative'
        }}>
          <ListItem sx={{
            paddingX: 5
          }}>
            <ListItemText sx={{
              color: colors.green
            }} primary={`Todos los clientes (${clients.length})`}
              primaryTypographyProps={{
                fontFamily: 'Nunito',
                fontSize: '25px',
                fontWeight: 'bold',
                color: colors.green
              }} />
            <ListItemIcon sx={{ cursor: 'pointer' }}>
              <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                sx={{
                  overflow: 'hidden'
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option} onClick={() => {
                    Swal.fire({
                      title: '¿Deseas descargar los clientes en formato excel?',
                      text: "Se descargará un archivo con todos los clientes registrados en el sistema",
                      showDenyButton: true,
                      confirmButtonText: `Descargar`,
                      denyButtonText: `Cancelar`,
                      confirmButtonColor: colors.green,
                      denyButtonColor: colors.red
                    }).then((result) => {
                      if (result.isConfirmed) {
                        createExcel(true);
                      } else if (result.isDenied) {
                        Swal.fire('Descarga cancelada', '', 'info')
                      }
                    })
                  }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </ListItemIcon>
          </ListItem>
          <TextField
            placeholder="Buscar cliente por nombre o RUT"
            variant='outlined'
            sx={{
              width: '90%',
              mb: 1,
              backgroundColor: '#DBDBDC',
              borderRadius: '50px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {paginatedRequests.map((client) => (
            <ListItem
              onClick={() => {
                setSelectedClient(client);
                setOpenViewClient(true);
              }}
              key={client.id} sx={{
                cursor: 'pointer',
                margin: '2px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '90%',
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '1px solid #1212121F',
              }}>
              <ListItemAvatar>
                {
                  client.addType != 'old' ? (<img src={client.restaurant} alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />) : (
                    <Avatar sx={{
                      backgroundColor: colors.green
                    }}>
                      {client.socialReason.substring(0, 1)}
                    </Avatar>
                  )
                }
              </ListItemAvatar>
              <ListItemText sx={{ ml: 1 }}
                primaryTypographyProps={{
                  fontFamily: 'Nunito'
                }}
                secondaryTypographyProps={{
                  fontFamily: 'Nunito'
                }}
                primary={client.fantasyName ? capitalize(client.fantasyName) : capitalize(client.socialReason)}
                secondary={'Vendedor a cargo: ' + capitalize(getSellerNameById(client.idSeller))}
              />
              <ListItemIcon>
                {client.emailOwner && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: '0',
                      width: 'auto'
                    }}
                  >
                    <OpenInBrowserTwoTone
                      onClick={(e) => {
                        e.stopPropagation();
                        openNewTab('https://espacio.haulmer.com');
                      }}
                      sx={{ fontSize: '30px' }} 
                    />
                    <Typography variant="caption" sx={{ fontSize: '12px', marginTop: '2px' }}>
                      {client.emailOwner}
                    </Typography>
                  </Box>
                )}
              </ListItemIcon>
            </ListItem>
          ))
          }
          <Pagination
            count={Math.ceil(filteredClients.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={{ mt: 2 }}
          />
          {
            openViewClient && <ModalViewClient id={selectedClient.id} data={selectedClient} isOpen={openViewClient} onClose={() => setOpenViewClient(false)} nameSeller={
              getSellerNameById(selectedClient.idSeller)
            } isPays={false} reloadData={() => {
              getRequests();
            }} />
          }
        </Box>
      }
    </div>
  )
}