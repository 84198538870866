import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';

export const getClientsBySeller = (idSeller, setClients) => {
    const unsubscribe = onSnapshot(query(
        collection(db, 'users'),
        where('idSeller', '==', idSeller),
        where('type', '==', 'client'),
        where('status', '==', 'accepted')
    ), (snapshot) => {
        const fetchedClients = [];
        snapshot.forEach(doc => {
            fetchedClients.push({ id: doc.id, ...doc.data() });
        });
        setClients(fetchedClients);
    });
    return unsubscribe;
};

export const getAllClientsByName = async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'client'),
        where('status', '==', 'accepted'),
        orderBy('socialReason', 'asc')
    ));
    const fetchedClients = [];
    querySnapshot.forEach(doc => {
        if (doc.addType !== 'old' || doc.addType == undefined) {
            fetchedClients.push({ id: doc.id, ...doc.data() });
        }
        // fetchedClients.push({ id: doc.id, ...doc.data() });
    });
    return fetchedClients;
}

export const getAllClients = async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'client'),
        where('status', '==', 'accepted'),
        orderBy('createdAt', 'desc')
    ));
    const fetchedClients = [];
    querySnapshot.forEach(doc => {
        if (doc.addType !== 'old' || doc.addType == undefined) {
            fetchedClients.push({ id: doc.id, ...doc.data() });
        }
        // fetchedClients.push({ id: doc.id, ...doc.data() });
    });
    return fetchedClients;
}

export const getAllClientsMails = async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'client'),
        where('status', '==', 'accepted'),
        orderBy('createdAt', 'desc')
    ));

    const fetchedClients = [];
    querySnapshot.forEach(doc => {
        const clientData = doc.data();
        if (clientData.emailEnterprise.includes('@')) {
            fetchedClients.push({ id: doc.id, ...clientData });
        }
    });

    return fetchedClients;
};

export const getCuotasDebtors = async (idSeller) => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'client'),
        where('dataPays.status', '==', 'pending')
    ));
    const fetchedClients = [];
    querySnapshot.forEach(doc => {
        fetchedClients.push({ id: doc.id, ...doc.data() });
    });
    return fetchedClients;
}

export const getPieDebtors = async (idSeller) => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'client'),
        where('dataPays.piePayed', '==', 'pending')
    ));
    const fetchedClients = [];
    querySnapshot.forEach(doc => {
        fetchedClients.push({ id: doc.id, ...doc.data() });
    });
    return fetchedClients;
}

export const getUserById = async (id) => {
    const docRes = await getDoc(doc(db, 'users', id));
    return docRes.data();
};

export const disableClient = async (idClient) => {
    console.log(idClient);
    
    const clientRef = doc(db, 'users', idClient);

    await updateDoc(clientRef, {
        status: "inactive"
    });
    return true;
}

export async function updateOldUsersDate() {
    try {
        // Crea una referencia a la colección de usuarios
        const usersCollection = collection(db, 'users');

        // Crea una consulta para obtener los documentos con addType == 'old'
        const q = query(usersCollection, where('addType', '==', 'old'));

        // Obtén los documentos
        const querySnapshot = await getDocs(q);

        // Fecha actual menos un año
        const today = new Date();
        const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 2));

        // Actualiza cada documento
        for (const docSnapshot of querySnapshot.docs) {
            const docRef = doc(db, 'users', docSnapshot.id);
            await updateDoc(docRef, {
                createdAt: oneYearAgo
            });
            console.log(`Updated document ${docSnapshot.id}`);
        }

        console.log('All documents updated successfully.');
    } catch (error) {
        console.error('Error updating documents:', error);
    }
}

export const addClient = async (socialReason, rut, email) => {
    try {
        const newClient = {
            addType: 'old',
            idSeller: 'gamuLcPThZbV6PkcrfsVQDjarsJ2',
            latitude: '',
            longitude: '',
            type: 'client',
            status: 'accepted',
            fantasyName: '',
            monthlyAverage: '',
            typePersonEnterprise: '',
            socialReason: capitalizeWords(socialReason),
            rutEnterprise: formatRUT(rut),
            addressEnterprise: '',
            phoneEnterprise: '',
            emailEnterprise: email,
            comuneEnterprise: '',
            activitySIIEnterprise: '',
            keySIIEnterprise: '',
            nameRepresent: '',
            rutRepresent: '',
            phoneRepresent: '',
            emailRepresent: '',
            addressRepresent: '',
            accountBank: '',
            accountType: '',
            accountNumber: '',
            accountMain: '',
            debit: '',
            credit: '',
            extras: '',
            pays: '',
            invoice: '',
            bill: '',
            createdAt: serverTimestamp(),
            offices: [],
            dataPays: {
                type: '',
                cuotas: null,
                pie: null,
                piePayed: '',
                status: 'payed'
            }
        };

        await addDoc(collection(db, 'users'), newClient);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }

}

export const updateClient = async (clientId, updatedFields) => {
    try {
        const clientRef = doc(db, 'users', clientId);

        await updateDoc(clientRef, updatedFields);

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getClientsForDownload = async () => {
    const querySnapshot = await getDocs(query(
        collection(db, 'users'),
        where('type', '==', 'client'),
        where('status', '==', 'accepted'),
        orderBy('createdAt', 'desc')
    ));
    const fetchedClients = [];
    querySnapshot.forEach(doc => {
        fetchedClients.push({ id: doc.id, ...doc.data() });
    });
    return fetchedClients;
}