import { doc, getDoc, collection, updateDoc, getDocs, query, where, addDoc } from 'firebase/firestore';
import { db } from './config_firebase';

const getPendingSupportRequests = async () => {
  try {
    const querySnapshot = await getDocs(query(
      collection(db, 'requests'),
      where('status', '==', 'pending')
    ));
    const pendingRequests = [];
    querySnapshot.forEach(doc => {
      pendingRequests.push({ id: doc.id, ...doc.data() });
    });
    return pendingRequests;
  } catch (error) {
    console.error('Error getting pending requests:', error);
    return [];
  }
}

const createRequest = async (allData, idSeller, idClient, isToSupport) => {
  try {
    const requestDocRef = doc(db, 'requests', 'nuy4Y5sD7IPJii7t9oJ1');
    const requestDocSnap = await getDoc(requestDocRef);

    if (requestDocSnap.exists()) {
      let numberLastRequest = requestDocSnap.data().number + 1;

      await updateDoc(requestDocRef, {
        number: numberLastRequest
      });

      const newRequestRef = await addDoc(collection(db, 'requests'), {
        nameClient: allData.nameClient || '',
        addressClient: allData.addressClient || '',
        number: numberLastRequest,
        idClient: idClient || '',
        idSeller: idSeller || '',
        // imageClient: image || '',
        extraData: allData.extraData,
        status: 'pending',
        latitude: allData.lat,
        longitude: allData.long,
        priority: allData.priority,
        type: allData.type,
        createdAt: new Date(),
      });

      return newRequestRef.id;
    } else {
      console.log("El documento no existe.");
      return false;
    }
  } catch (error) {
    console.error("Error creando la solicitud: ", error);
    return false;
  }
};


export { createRequest, getPendingSupportRequests }