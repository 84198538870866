import { useTheme } from '@emotion/react';
import { Avatar, Box, Fab, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Pagination, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllPromotors } from '../database/sellers';
import ReusableDrawer from '../components/drawer';
import loadingImg from '../assets/loading.svg';
import colors from '../utils/colors';
import { Add, ArrowForwardIos } from '@mui/icons-material';
import { capitalize } from '../utils/capitalize';
import ModalViewPromoter from '../components/modal_view_promotor';
import ModalNewPromotor from '../components/modal_new_promotor';


export default function Promoters() {

    const [drawerOpen, setDrawerOpen] = useState(true);
    const [promoters, setPromoters] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [openNewPromoter, setOpenNewPromoter] = useState(false);
    const [selectedPromoter, setSelectedPromoter] = useState({});
    const [itemsPerPage] = useState(8);
    const [page, setPage] = useState(1);

    const idUser = useSelector(state => state.auth.idUser);
    const [loading, setLoading] = useState(true);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);

    const theme = useTheme();
    const isSmallScreen = false // useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = false // useMediaQuery(theme.breakpoints.down('md'));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = promoters.filter(client =>
            (client.name && client.name.toLowerCase().includes(lowercasedFilter))
        );
        setFilteredClients(filteredData);
        setPage(1);
    }, [searchTerm, promoters]);

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRequests = filteredClients.slice(startIndex, endIndex);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    async function getRequests() {
        setLoading(true);
        const response = await getAllPromotors();
        console.log(response);

        setPromoters(response);
        setLoading(false);
    }

    useEffect(() => {
        getRequests();
    }, [idUser]);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (

        <div style={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'start',
            overflowX: 'hidden',
            flex: 1,
            backgroundColor: '#F7F7F8',
            height: '100vh'
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'promoters'} />

            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowX: 'hidden',
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: isSmallScreen ? '80px' : '100px', height: isSmallScreen ? '80px' : '100px' }} />
                    <p style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>Cargando mis vendedores...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    flex: 1,
                    alignItems: 'center',
                    overflowX: 'hidden',
                    justifyContent: 'center',
                    alignContent: 'center',
                    justifyItems: 'center'

                }}>
                    <h2 style={{
                        color: colors.green,
                        fontSize: isSmallScreen ? '1.2rem' : '1.5rem'  // Ajuste del tamaño del título
                    }}>Promotores</h2>

                    {
                        paginatedRequests.map((seller) => (
                            <ListItem
                                onClick={() => {
                                    setSelectedPromoter(seller);
                                    setIsOpen(true);
                                }}
                                key={seller.id}
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: isSmallScreen ? '95%' : isMediumScreen ? '85%' : '75%',  // Ajuste del ancho según el tamaño de la pantalla
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    border: '1px solid #1212121F',
                                    margin: isSmallScreen ? '3px' : '5px',  // Márgenes más pequeños en pantallas pequeñas
                                    padding: isSmallScreen ? '8px' : '10px'  // Ajuste de padding
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{
                                        border: '1px solid #1212121F',
                                        width: isSmallScreen ? '40px' : '50px',
                                        height: isSmallScreen ? '40px' : '50px'
                                    }} src={seller.image} alt="Logo" />
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{ ml: 1 }}
                                    primaryTypographyProps={{
                                        fontFamily: 'Nunito',
                                        fontSize: isSmallScreen ? '0.85rem' : '1rem'
                                    }}
                                    secondaryTypographyProps={{
                                        fontFamily: 'Nunito',
                                        fontSize: isSmallScreen ? '0.75rem' : '0.9rem'
                                    }}
                                    primary={capitalize(seller.name ?? "")}
                                    secondary={capitalize(seller.email ?? "")}
                                />
                                <ListItemIcon>
                                    <ArrowForwardIos sx={{ fontSize: isSmallScreen ? '1rem' : '1.2rem' }} />  {/* Icono más pequeño en pantallas pequeñas */}
                                </ListItemIcon>
                            </ListItem>
                        ))
                    }

                    <Pagination
                        count={Math.ceil(filteredClients.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        sx={{ mt: 2 }}
                    />

                    <Fab
                        aria-label="add"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            backgroundColor: colors.green,
                            borderRadius: '20px',
                            width: isSmallScreen ? '40px' : '56px',
                            height: isSmallScreen ? '40px' : '56px'
                        }}
                        onClick={() => setOpenNewPromoter(true)}
                    >
                        <Add sx={{
                            color: 'white',
                            fontSize: isSmallScreen ? '1.2rem' : '1.5rem'  
                        }} />
                    </Fab>

                    {isOpen && <ModalViewPromoter open={isOpen} handleClose={toggleModal} data={selectedPromoter} reloadData={getRequests} />}
                    {openNewPromoter && <ModalNewPromotor isOpen={openNewPromoter} onClose={() => {
                        setOpenNewPromoter(false)
                    }} reloadData={getRequests} />}


                </Box>
            )}
        </div>
    );

}
